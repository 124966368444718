<template>
  <div class="text-left">
    <!-- <BaseHeader :title="'WriterLevels'"></BaseHeader> -->

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else>

      <!-- {{writerLevels}} -->
      <div class=" my-2 border-0 ">
       


        <div class=" my-2">

        <div class="card orderlisting--card py-2">
        <section class=" order-listing-header-main p-3  ">  
          <div class=" d-flex align-items-center w-100  justify-content-end header-elements-inline pb-0">

            
            <div class="header-elements">
              <button 
                color="primary"
                class="my-auto btn float-right btn-add ml-2"
                size="small"
                @click.stop="
                  edit = false;
                  writerLevelsForm.reset();
                  $store.state.dialog = true;
                  "
                >Add writer level</button
                  >
            </div>
          </div>

        </section>

        <div class=" mx-0  order-table-custom ">

          <div  class=" order-listing-header    row  p-3 mx-sm-0 mx-1 ">  
            <div class="col-1 text-left text--dark--custom "> ID </div>
            <div class="col-2 text-left text--dark--custom "> Level</div>
            <div class="col-2 text-left text--dark--custom">Orders to get here</div>
            <div class="col-2 text-left text--dark--custom"> Max Bids</div>
            <div class="col-1 text-left text--dark--custom">Max Claims</div>
            <div class="col-1 text-left text--dark--custom">Max Orders</div>
            <div class="col-2 text-left text--dark--custom"> Amount Per Page</div>
            <div class="col-1 text-left text--dark--custom">Action </div>
        </div>


        <div v-if="writerLevels.length > 0" class="">
        <div v-for="(item, index) in writerLevels" :key="index + 'ords'" class="levels-listing-body row px-3 py-1 mx-sm-0 mx-1 ">  
            <div class="col-1 d-flex align-items-start justify-content-start text-left">	
              <span class="px-2"
                >{{ item.id || "" }}</span>
              
            </div>
            <div class="col-2 text-left">
              <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.level || "" }}</div>
            </div>
            <div class="col-2 text-left">{{ item.orders_to_get_here || "" }}</div>
            <div class="col-2 text-left">  {{ item.max_bids || "" }} </div>
            <div class="col-1 text-left">
              {{item.max_claims}}
            </div>
            <div class="col-1 text-left">
              {{item.max_orders}}

              
            
            </div>
            <div class="col-2 text-left">
              <span class="" 
              >${{ item.amount_per_page || "" }}</span
            >
            </div>
            <div class="col-1 text-left  ">
              <v-btn
              elevation="0"
              class="white--text"
              @click="
                editMode(item);
                edit = true;
                $store.state.dialog = true;
              "
              fab
              x-small
            >
            <v-icon scale="1.3" name="eye" color="black" />

            </v-btn>
            </div>
        </div>
        </div>

        </div>
        <div class="p-3 d-flex align-items-center justify-content-center w-100 "  v-if="writerLevels.length < 1">
          
              <strong class="order_empty  text-center" >No discounts.</strong>
          
        </div>
        </div>
        </div>
      </div>

      <!-- clients modal -->
      <v-dialog
        v-model="$store.state.dialog"
        class="text-left"
        transition="dialog-top-transition"
        max-width="600"
      >
        <v-card :loading="writerLevelsForm.busy">
          <v-card-title class="text-h5">
            {{
              edit
                ? `Update ${writerLevelsForm.level || ""}`
                : "New Writer Level"
            }}
          </v-card-title>
          <v-card-text>
            <!--  form -->
            <form
              class="flex-fill text-left"
              ref="writerLevelsForm"
              @submit.prevent="createWriterLevel"
            >
              <div class>
                <div class="mb-3">
                  <span class="d-block text-muted"
                    >All fields are required</span
                  >
                </div>

                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Level"
                      name="level"
                      type="level"
                      v-model="writerLevelsForm.level"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="level"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Orders to get here"
                      name="orders_to_get_here"
                      v-model="writerLevelsForm.orders_to_get_here"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="orders_to_get_here"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Max Bids"
                      name="max_bids"
                      v-model="writerLevelsForm.max_bids"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="max_bids"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Max Claims"
                      name="max_claims"
                      v-model="writerLevelsForm.max_claims"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="max_claims"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Max Orders"
                      name="max_orders"
                      v-model="writerLevelsForm.max_orders"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="max_orders"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      dense
                      outlined
                      label="Amount Per Page"
                      name="amount_per_page"
                      v-model="writerLevelsForm.amount_per_page"
                      hide-details
                    />
                    <div class="v-text-field__details">
                      <div class="v-messages theme--light">
                        <div class="v-messages__wrapper">
                          <has-error
                            :form="writerLevelsForm"
                            class="v-messages theme--light error--text"
                            field="amount_per_page"
                          ></has-error>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>

                <v-btn-toggle>
                  <v-btn
                    :disabled="writerLevelsForm.busy"
                    type="submit"
                    color="teal"
                    class="white--text"
                    >{{ edit ? "Update" : "Save" }}</v-btn
                  >
                  <v-btn
                    type="button"
                    @click="$store.state.dialog = !$store.state.dialog"
                    >Close</v-btn
                  >
                </v-btn-toggle>
              </div>
            </form>

            <!-- / form -->
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- clients modal -->
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "WriterLevels",
  computed: {
    ...mapState("writerlevels", [
      "writerLevelsForm",
      "loading",
      "writerLevels",
      "headers",
    ]),
    // ...mapState("clients", ["clients"]),
  },
  data() {
    return {
      countries: [],
      edit: false,
    };
  },

  methods: {
    ...mapActions("writerlevels", ["getWriterLevels"]),
    // ...mapActions("clients", ["getClients"]),
    createWriterLevel() {
      this.edit
        ? this.writerLevelsForm
            .put(`${this.$baseUrl}/Setups/WriterLevels`)
            .then((res) => {
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `Writer level updated successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.getWriterLevels();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text:err.response.data
                  ? err.response.data.Message
                  : `Error while updating writer level`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            }) 
        : this.writerLevelsForm
            .post(`${this.$baseUrl}/Setups/WriterLevels`)
            .then((res) => {
              this.$store.state.dialog = false;
              

              this.$swal({
                icon: 'success',
                title: 'Success',
                text: `Writer level created successfully.`,
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                  },
                timer: 3000,
                timerProgressBar:  true,
                // footer: '<a href="/">Why do I have this issue?</a>'
              })
              this.getWriterLevels();
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
             

              this.$swal({
              icon: 'error',
              title: 'Error',
              text: err.response.data
                  ? err.response.data.Message
                  : `Error while creating writer level`,
              showClass: {
                popup: 'animate__animated animate__fadeInDown'
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
              },
              timer: 3000,
              timerProgressBar: true,
          })
            });
    },

    editMode(item) {
      Object.assign(this.writerLevelsForm, { ...item });
    },
  },
  async mounted() {
    await this.getWriterLevels();
  },
};
</script>